@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles/checkboxes.scss';

input[type='text']:focus { box-shadow: none; }
.gv-tel-link { margin-left: 5px !important; }

.overflow-y-overlay {
  overflow-y: overlay
}
.dark .MuiDataGrid-root ::-webkit-scrollbar-track-piece {
  // back
  background-color: transparent;
}

.dark .MuiDataGrid-root ::-webkit-scrollbar {
//   appearance: none;
// background-color: transparent;
    width: 7px;
}

.dark .MuiDataGrid-root ::-webkit-scrollbar:horizontal {
  height: 7px;
}

.dark .MuiDataGrid-root ::-webkit-scrollbar-corner {
  background-color: transparent;
}

.dark .MuiDataGrid-root ::-webkit-scrollbar-thumb {
  background-color: #E5E7EB;
//   padding: 5px;
  border-radius: 9px;
}
